/**
* .button
*/

.button,
input[type="submit"],
button { 
	&:not(.blank):not(.muted) {
		@include btn;
	}

	&.muted {
		color: color(brand, gold);

		&:after {
			content: '\2192';
			display: inline-block;
			vertical-align: middle;
			margin-left: 5px;
		}

		@include media-breakpoint-up(sm) {
			font-size: font(medium);
		}
	}	

	&.blank {
		padding: 0;
	}
}

/**
* button
*/

button {
	appearance: none;
	background-color: transparent;
	border: none;
	outline: none;
	border-radius: 0px;
}
