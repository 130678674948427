/**
* body
*/

body { 
	letter-spacing: 0.045rem;
	font-size: font(reset);
	font-weight: $fw-light;
	overflow-x: hidden; 
	color: color(mono, black);
}

/**
* *
*/

* {
	letter-spacing: inherit;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

::selection {
  background: color(brand, navy) !important;
  color: color(mono, white) !important;
}

::-moz-selection {
  background: color(brand, navy) !important;
  color: color(mono, white) !important;
}