@keyframes spinner {

	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}

}

@keyframes fadeUp {
	0% {
		opacity: 0;
		transform: translateY($sp-extra-large) scale(0.8);
	}

	100% {
		opacity: 1;
		transform: translateY(0) scale(1);
	}
}

@keyframes fadeUpAlt {
	0% {
		opacity: 0;
		transform: translateY($sp-small);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}


@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes fadeFromRight {

	0% {
		opacity: 0;
		transform: translateX($sp-large);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
	
}

@keyframes fadeFromLeft {

	0% {
		opacity: 0;
		transform: translateX(-$sp-large);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
	
}
