/**
* .container-fluid
*/

.container-fluid,
.container {
	max-width: 1628px;
	padding-left: $sp-medium;
	padding-right: $sp-medium;

	&--xs {
		max-width: 700px;
	}

	&--sm {
		max-width: 1000px;
	}

	&--md {
		max-width: 1400px;
	}

	&--lg {
		max-width: 1900px;
	}
}