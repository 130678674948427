/**
* colors
*/
/**
* .fonts
*/
/**
* spacers
*/
/**
* used for margins
*/
/**
* heights
*/
/**
* other
*/
/**
* timings
*/
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: navigo, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 667px) {
  .container, .container-xxs, .container-xs, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-xxs, .container-xs, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-xxs, .container-xs, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-xxs, .container-xs, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row, form ul {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .gfield, .col,
.col-auto, .col-xxs-1, .col-xxs-2, .col-xxs-3, .col-xxs-4, .col-xxs-5, .col-xxs-6, .col-xxs-7, .col-xxs-8, .col-xxs-9, .col-xxs-10, .col-xxs-11, .col-xxs-12, .col-xxs,
.col-xxs-auto, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs,
.col-xs-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .gfield:not(.gform_textarea), .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto, .col-xxxl-1, .col-xxxl-2, .col-xxxl-3, .col-xxxl-4, .col-xxxl-5, .col-xxxl-6, .col-xxxl-7, .col-xxxl-8, .col-xxxl-9, .col-xxxl-10, .col-xxxl-11, .col-xxxl-12, .col-xxxl,
.col-xxxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12, .gfield {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 320px) {
  .col-xxs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxs-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxs-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxs-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxs-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxs-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxs-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxs-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxs-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxs-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxs-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxs-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxs-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxs-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxs-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxs-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxs-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxs-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxs-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxs-first {
    order: -1; }
  .order-xxs-last {
    order: 13; }
  .order-xxs-0 {
    order: 0; }
  .order-xxs-1 {
    order: 1; }
  .order-xxs-2 {
    order: 2; }
  .order-xxs-3 {
    order: 3; }
  .order-xxs-4 {
    order: 4; }
  .order-xxs-5 {
    order: 5; }
  .order-xxs-6 {
    order: 6; }
  .order-xxs-7 {
    order: 7; }
  .order-xxs-8 {
    order: 8; }
  .order-xxs-9 {
    order: 9; }
  .order-xxs-10 {
    order: 10; }
  .order-xxs-11 {
    order: 11; }
  .order-xxs-12 {
    order: 12; }
  .offset-xxs-0 {
    margin-left: 0; }
  .offset-xxs-1 {
    margin-left: 8.33333%; }
  .offset-xxs-2 {
    margin-left: 16.66667%; }
  .offset-xxs-3 {
    margin-left: 25%; }
  .offset-xxs-4 {
    margin-left: 33.33333%; }
  .offset-xxs-5 {
    margin-left: 41.66667%; }
  .offset-xxs-6 {
    margin-left: 50%; }
  .offset-xxs-7 {
    margin-left: 58.33333%; }
  .offset-xxs-8 {
    margin-left: 66.66667%; }
  .offset-xxs-9 {
    margin-left: 75%; }
  .offset-xxs-10 {
    margin-left: 83.33333%; }
  .offset-xxs-11 {
    margin-left: 91.66667%; } }

@media (min-width: 568px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xs-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xs-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xs-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xs-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xs-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xs-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xs-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xs-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xs-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xs-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xs-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xs-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xs-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xs-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xs-first {
    order: -1; }
  .order-xs-last {
    order: 13; }
  .order-xs-0 {
    order: 0; }
  .order-xs-1 {
    order: 1; }
  .order-xs-2 {
    order: 2; }
  .order-xs-3 {
    order: 3; }
  .order-xs-4 {
    order: 4; }
  .order-xs-5 {
    order: 5; }
  .order-xs-6 {
    order: 6; }
  .order-xs-7 {
    order: 7; }
  .order-xs-8 {
    order: 8; }
  .order-xs-9 {
    order: 9; }
  .order-xs-10 {
    order: 10; }
  .order-xs-11 {
    order: 11; }
  .order-xs-12 {
    order: 12; }
  .offset-xs-0 {
    margin-left: 0; }
  .offset-xs-1 {
    margin-left: 8.33333%; }
  .offset-xs-2 {
    margin-left: 16.66667%; }
  .offset-xs-3 {
    margin-left: 25%; }
  .offset-xs-4 {
    margin-left: 33.33333%; }
  .offset-xs-5 {
    margin-left: 41.66667%; }
  .offset-xs-6 {
    margin-left: 50%; }
  .offset-xs-7 {
    margin-left: 58.33333%; }
  .offset-xs-8 {
    margin-left: 66.66667%; }
  .offset-xs-9 {
    margin-left: 75%; }
  .offset-xs-10 {
    margin-left: 83.33333%; }
  .offset-xs-11 {
    margin-left: 91.66667%; } }

@media (min-width: 667px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6, .gfield:not(.gform_textarea) {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1600px) {
  .col-xxxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxxl-first {
    order: -1; }
  .order-xxxl-last {
    order: 13; }
  .order-xxxl-0 {
    order: 0; }
  .order-xxxl-1 {
    order: 1; }
  .order-xxxl-2 {
    order: 2; }
  .order-xxxl-3 {
    order: 3; }
  .order-xxxl-4 {
    order: 4; }
  .order-xxxl-5 {
    order: 5; }
  .order-xxxl-6 {
    order: 6; }
  .order-xxxl-7 {
    order: 7; }
  .order-xxxl-8 {
    order: 8; }
  .order-xxxl-9 {
    order: 9; }
  .order-xxxl-10 {
    order: 10; }
  .order-xxxl-11 {
    order: 11; }
  .order-xxxl-12 {
    order: 12; }
  .offset-xxxl-0 {
    margin-left: 0; }
  .offset-xxxl-1 {
    margin-left: 8.33333%; }
  .offset-xxxl-2 {
    margin-left: 16.66667%; }
  .offset-xxxl-3 {
    margin-left: 25%; }
  .offset-xxxl-4 {
    margin-left: 33.33333%; }
  .offset-xxxl-5 {
    margin-left: 41.66667%; }
  .offset-xxxl-6 {
    margin-left: 50%; }
  .offset-xxxl-7 {
    margin-left: 58.33333%; }
  .offset-xxxl-8 {
    margin-left: 66.66667%; }
  .offset-xxxl-9 {
    margin-left: 75%; }
  .offset-xxxl-10 {
    margin-left: 83.33333%; }
  .offset-xxxl-11 {
    margin-left: 91.66667%; } }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 320px) {
  .d-xxs-none {
    display: none !important; }
  .d-xxs-inline {
    display: inline !important; }
  .d-xxs-inline-block {
    display: inline-block !important; }
  .d-xxs-block {
    display: block !important; }
  .d-xxs-table {
    display: table !important; }
  .d-xxs-table-row {
    display: table-row !important; }
  .d-xxs-table-cell {
    display: table-cell !important; }
  .d-xxs-flex {
    display: flex !important; }
  .d-xxs-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 568px) {
  .d-xs-none {
    display: none !important; }
  .d-xs-inline {
    display: inline !important; }
  .d-xs-inline-block {
    display: inline-block !important; }
  .d-xs-block {
    display: block !important; }
  .d-xs-table {
    display: table !important; }
  .d-xs-table-row {
    display: table-row !important; }
  .d-xs-table-cell {
    display: table-cell !important; }
  .d-xs-flex {
    display: flex !important; }
  .d-xs-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 667px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  .d-xxxl-none {
    display: none !important; }
  .d-xxxl-inline {
    display: inline !important; }
  .d-xxxl-inline-block {
    display: inline-block !important; }
  .d-xxxl-block {
    display: block !important; }
  .d-xxxl-table {
    display: table !important; }
  .d-xxxl-table-row {
    display: table-row !important; }
  .d-xxxl-table-cell {
    display: table-cell !important; }
  .d-xxxl-flex {
    display: flex !important; }
  .d-xxxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 320px) {
  .flex-xxs-row {
    flex-direction: row !important; }
  .flex-xxs-column {
    flex-direction: column !important; }
  .flex-xxs-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxs-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxs-wrap {
    flex-wrap: wrap !important; }
  .flex-xxs-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxs-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxs-fill {
    flex: 1 1 auto !important; }
  .flex-xxs-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxs-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxs-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxs-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxs-start {
    justify-content: flex-start !important; }
  .justify-content-xxs-end {
    justify-content: flex-end !important; }
  .justify-content-xxs-center {
    justify-content: center !important; }
  .justify-content-xxs-between {
    justify-content: space-between !important; }
  .justify-content-xxs-around {
    justify-content: space-around !important; }
  .align-items-xxs-start {
    align-items: flex-start !important; }
  .align-items-xxs-end {
    align-items: flex-end !important; }
  .align-items-xxs-center {
    align-items: center !important; }
  .align-items-xxs-baseline {
    align-items: baseline !important; }
  .align-items-xxs-stretch {
    align-items: stretch !important; }
  .align-content-xxs-start {
    align-content: flex-start !important; }
  .align-content-xxs-end {
    align-content: flex-end !important; }
  .align-content-xxs-center {
    align-content: center !important; }
  .align-content-xxs-between {
    align-content: space-between !important; }
  .align-content-xxs-around {
    align-content: space-around !important; }
  .align-content-xxs-stretch {
    align-content: stretch !important; }
  .align-self-xxs-auto {
    align-self: auto !important; }
  .align-self-xxs-start {
    align-self: flex-start !important; }
  .align-self-xxs-end {
    align-self: flex-end !important; }
  .align-self-xxs-center {
    align-self: center !important; }
  .align-self-xxs-baseline {
    align-self: baseline !important; }
  .align-self-xxs-stretch {
    align-self: stretch !important; } }

@media (min-width: 568px) {
  .flex-xs-row {
    flex-direction: row !important; }
  .flex-xs-column {
    flex-direction: column !important; }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xs-wrap {
    flex-wrap: wrap !important; }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xs-fill {
    flex: 1 1 auto !important; }
  .flex-xs-grow-0 {
    flex-grow: 0 !important; }
  .flex-xs-grow-1 {
    flex-grow: 1 !important; }
  .flex-xs-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xs-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xs-start {
    justify-content: flex-start !important; }
  .justify-content-xs-end {
    justify-content: flex-end !important; }
  .justify-content-xs-center {
    justify-content: center !important; }
  .justify-content-xs-between {
    justify-content: space-between !important; }
  .justify-content-xs-around {
    justify-content: space-around !important; }
  .align-items-xs-start {
    align-items: flex-start !important; }
  .align-items-xs-end {
    align-items: flex-end !important; }
  .align-items-xs-center {
    align-items: center !important; }
  .align-items-xs-baseline {
    align-items: baseline !important; }
  .align-items-xs-stretch {
    align-items: stretch !important; }
  .align-content-xs-start {
    align-content: flex-start !important; }
  .align-content-xs-end {
    align-content: flex-end !important; }
  .align-content-xs-center {
    align-content: center !important; }
  .align-content-xs-between {
    align-content: space-between !important; }
  .align-content-xs-around {
    align-content: space-around !important; }
  .align-content-xs-stretch {
    align-content: stretch !important; }
  .align-self-xs-auto {
    align-self: auto !important; }
  .align-self-xs-start {
    align-self: flex-start !important; }
  .align-self-xs-end {
    align-self: flex-end !important; }
  .align-self-xs-center {
    align-self: center !important; }
  .align-self-xs-baseline {
    align-self: baseline !important; }
  .align-self-xs-stretch {
    align-self: stretch !important; } }

@media (min-width: 667px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1400px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xxxl-row {
    flex-direction: row !important; }
  .flex-xxxl-column {
    flex-direction: column !important; }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxxl-center {
    justify-content: center !important; }
  .justify-content-xxxl-between {
    justify-content: space-between !important; }
  .justify-content-xxxl-around {
    justify-content: space-around !important; }
  .align-items-xxxl-start {
    align-items: flex-start !important; }
  .align-items-xxxl-end {
    align-items: flex-end !important; }
  .align-items-xxxl-center {
    align-items: center !important; }
  .align-items-xxxl-baseline {
    align-items: baseline !important; }
  .align-items-xxxl-stretch {
    align-items: stretch !important; }
  .align-content-xxxl-start {
    align-content: flex-start !important; }
  .align-content-xxxl-end {
    align-content: flex-end !important; }
  .align-content-xxxl-center {
    align-content: center !important; }
  .align-content-xxxl-between {
    align-content: space-between !important; }
  .align-content-xxxl-around {
    align-content: space-around !important; }
  .align-content-xxxl-stretch {
    align-content: stretch !important; }
  .align-self-xxxl-auto {
    align-self: auto !important; }
  .align-self-xxxl-start {
    align-self: flex-start !important; }
  .align-self-xxxl-end {
    align-self: flex-end !important; }
  .align-self-xxxl-center {
    align-self: center !important; }
  .align-self-xxxl-baseline {
    align-self: baseline !important; }
  .align-self-xxxl-stretch {
    align-self: stretch !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1.15rem !important; }

.mt-3,
.my-3 {
  margin-top: 1.15rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1.15rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1.15rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1.15rem !important; }

.m-4 {
  margin: 2.25rem !important; }

.mt-4,
.my-4 {
  margin-top: 2.25rem !important; }

.mr-4,
.mx-4 {
  margin-right: 2.25rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 2.25rem !important; }

.ml-4,
.mx-4 {
  margin-left: 2.25rem !important; }

.m-5 {
  margin: 5rem !important; }

.mt-5,
.my-5 {
  margin-top: 5rem !important; }

.mr-5,
.mx-5 {
  margin-right: 5rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 5rem !important; }

.ml-5,
.mx-5 {
  margin-left: 5rem !important; }

.m-6 {
  margin: 6rem !important; }

.mt-6,
.my-6 {
  margin-top: 6rem !important; }

.mr-6,
.mx-6 {
  margin-right: 6rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 6rem !important; }

.ml-6,
.mx-6 {
  margin-left: 6rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1.15rem !important; }

.pt-3,
.py-3 {
  padding-top: 1.15rem !important; }

.pr-3,
.px-3 {
  padding-right: 1.15rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1.15rem !important; }

.pl-3,
.px-3 {
  padding-left: 1.15rem !important; }

.p-4 {
  padding: 2.25rem !important; }

.pt-4,
.py-4 {
  padding-top: 2.25rem !important; }

.pr-4,
.px-4 {
  padding-right: 2.25rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 2.25rem !important; }

.pl-4,
.px-4 {
  padding-left: 2.25rem !important; }

.p-5 {
  padding: 5rem !important; }

.pt-5,
.py-5 {
  padding-top: 5rem !important; }

.pr-5,
.px-5 {
  padding-right: 5rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 5rem !important; }

.pl-5,
.px-5 {
  padding-left: 5rem !important; }

.p-6 {
  padding: 6rem !important; }

.pt-6,
.py-6 {
  padding-top: 6rem !important; }

.pr-6,
.px-6 {
  padding-right: 6rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 6rem !important; }

.pl-6,
.px-6 {
  padding-left: 6rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1.15rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1.15rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1.15rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1.15rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1.15rem !important; }

.m-n4 {
  margin: -2.25rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -2.25rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -2.25rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -2.25rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -2.25rem !important; }

.m-n5 {
  margin: -5rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -5rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -5rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -5rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -5rem !important; }

.m-n6 {
  margin: -6rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -6rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -6rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -6rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -6rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 320px) {
  .m-xxs-0 {
    margin: 0 !important; }
  .mt-xxs-0,
  .my-xxs-0 {
    margin-top: 0 !important; }
  .mr-xxs-0,
  .mx-xxs-0 {
    margin-right: 0 !important; }
  .mb-xxs-0,
  .my-xxs-0 {
    margin-bottom: 0 !important; }
  .ml-xxs-0,
  .mx-xxs-0 {
    margin-left: 0 !important; }
  .m-xxs-1 {
    margin: 0.25rem !important; }
  .mt-xxs-1,
  .my-xxs-1 {
    margin-top: 0.25rem !important; }
  .mr-xxs-1,
  .mx-xxs-1 {
    margin-right: 0.25rem !important; }
  .mb-xxs-1,
  .my-xxs-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxs-1,
  .mx-xxs-1 {
    margin-left: 0.25rem !important; }
  .m-xxs-2 {
    margin: 0.5rem !important; }
  .mt-xxs-2,
  .my-xxs-2 {
    margin-top: 0.5rem !important; }
  .mr-xxs-2,
  .mx-xxs-2 {
    margin-right: 0.5rem !important; }
  .mb-xxs-2,
  .my-xxs-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxs-2,
  .mx-xxs-2 {
    margin-left: 0.5rem !important; }
  .m-xxs-3 {
    margin: 1.15rem !important; }
  .mt-xxs-3,
  .my-xxs-3 {
    margin-top: 1.15rem !important; }
  .mr-xxs-3,
  .mx-xxs-3 {
    margin-right: 1.15rem !important; }
  .mb-xxs-3,
  .my-xxs-3 {
    margin-bottom: 1.15rem !important; }
  .ml-xxs-3,
  .mx-xxs-3 {
    margin-left: 1.15rem !important; }
  .m-xxs-4 {
    margin: 2.25rem !important; }
  .mt-xxs-4,
  .my-xxs-4 {
    margin-top: 2.25rem !important; }
  .mr-xxs-4,
  .mx-xxs-4 {
    margin-right: 2.25rem !important; }
  .mb-xxs-4,
  .my-xxs-4 {
    margin-bottom: 2.25rem !important; }
  .ml-xxs-4,
  .mx-xxs-4 {
    margin-left: 2.25rem !important; }
  .m-xxs-5 {
    margin: 5rem !important; }
  .mt-xxs-5,
  .my-xxs-5 {
    margin-top: 5rem !important; }
  .mr-xxs-5,
  .mx-xxs-5 {
    margin-right: 5rem !important; }
  .mb-xxs-5,
  .my-xxs-5 {
    margin-bottom: 5rem !important; }
  .ml-xxs-5,
  .mx-xxs-5 {
    margin-left: 5rem !important; }
  .m-xxs-6 {
    margin: 6rem !important; }
  .mt-xxs-6,
  .my-xxs-6 {
    margin-top: 6rem !important; }
  .mr-xxs-6,
  .mx-xxs-6 {
    margin-right: 6rem !important; }
  .mb-xxs-6,
  .my-xxs-6 {
    margin-bottom: 6rem !important; }
  .ml-xxs-6,
  .mx-xxs-6 {
    margin-left: 6rem !important; }
  .p-xxs-0 {
    padding: 0 !important; }
  .pt-xxs-0,
  .py-xxs-0 {
    padding-top: 0 !important; }
  .pr-xxs-0,
  .px-xxs-0 {
    padding-right: 0 !important; }
  .pb-xxs-0,
  .py-xxs-0 {
    padding-bottom: 0 !important; }
  .pl-xxs-0,
  .px-xxs-0 {
    padding-left: 0 !important; }
  .p-xxs-1 {
    padding: 0.25rem !important; }
  .pt-xxs-1,
  .py-xxs-1 {
    padding-top: 0.25rem !important; }
  .pr-xxs-1,
  .px-xxs-1 {
    padding-right: 0.25rem !important; }
  .pb-xxs-1,
  .py-xxs-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxs-1,
  .px-xxs-1 {
    padding-left: 0.25rem !important; }
  .p-xxs-2 {
    padding: 0.5rem !important; }
  .pt-xxs-2,
  .py-xxs-2 {
    padding-top: 0.5rem !important; }
  .pr-xxs-2,
  .px-xxs-2 {
    padding-right: 0.5rem !important; }
  .pb-xxs-2,
  .py-xxs-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxs-2,
  .px-xxs-2 {
    padding-left: 0.5rem !important; }
  .p-xxs-3 {
    padding: 1.15rem !important; }
  .pt-xxs-3,
  .py-xxs-3 {
    padding-top: 1.15rem !important; }
  .pr-xxs-3,
  .px-xxs-3 {
    padding-right: 1.15rem !important; }
  .pb-xxs-3,
  .py-xxs-3 {
    padding-bottom: 1.15rem !important; }
  .pl-xxs-3,
  .px-xxs-3 {
    padding-left: 1.15rem !important; }
  .p-xxs-4 {
    padding: 2.25rem !important; }
  .pt-xxs-4,
  .py-xxs-4 {
    padding-top: 2.25rem !important; }
  .pr-xxs-4,
  .px-xxs-4 {
    padding-right: 2.25rem !important; }
  .pb-xxs-4,
  .py-xxs-4 {
    padding-bottom: 2.25rem !important; }
  .pl-xxs-4,
  .px-xxs-4 {
    padding-left: 2.25rem !important; }
  .p-xxs-5 {
    padding: 5rem !important; }
  .pt-xxs-5,
  .py-xxs-5 {
    padding-top: 5rem !important; }
  .pr-xxs-5,
  .px-xxs-5 {
    padding-right: 5rem !important; }
  .pb-xxs-5,
  .py-xxs-5 {
    padding-bottom: 5rem !important; }
  .pl-xxs-5,
  .px-xxs-5 {
    padding-left: 5rem !important; }
  .p-xxs-6 {
    padding: 6rem !important; }
  .pt-xxs-6,
  .py-xxs-6 {
    padding-top: 6rem !important; }
  .pr-xxs-6,
  .px-xxs-6 {
    padding-right: 6rem !important; }
  .pb-xxs-6,
  .py-xxs-6 {
    padding-bottom: 6rem !important; }
  .pl-xxs-6,
  .px-xxs-6 {
    padding-left: 6rem !important; }
  .m-xxs-n1 {
    margin: -0.25rem !important; }
  .mt-xxs-n1,
  .my-xxs-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxs-n1,
  .mx-xxs-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxs-n1,
  .my-xxs-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxs-n1,
  .mx-xxs-n1 {
    margin-left: -0.25rem !important; }
  .m-xxs-n2 {
    margin: -0.5rem !important; }
  .mt-xxs-n2,
  .my-xxs-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxs-n2,
  .mx-xxs-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxs-n2,
  .my-xxs-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxs-n2,
  .mx-xxs-n2 {
    margin-left: -0.5rem !important; }
  .m-xxs-n3 {
    margin: -1.15rem !important; }
  .mt-xxs-n3,
  .my-xxs-n3 {
    margin-top: -1.15rem !important; }
  .mr-xxs-n3,
  .mx-xxs-n3 {
    margin-right: -1.15rem !important; }
  .mb-xxs-n3,
  .my-xxs-n3 {
    margin-bottom: -1.15rem !important; }
  .ml-xxs-n3,
  .mx-xxs-n3 {
    margin-left: -1.15rem !important; }
  .m-xxs-n4 {
    margin: -2.25rem !important; }
  .mt-xxs-n4,
  .my-xxs-n4 {
    margin-top: -2.25rem !important; }
  .mr-xxs-n4,
  .mx-xxs-n4 {
    margin-right: -2.25rem !important; }
  .mb-xxs-n4,
  .my-xxs-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-xxs-n4,
  .mx-xxs-n4 {
    margin-left: -2.25rem !important; }
  .m-xxs-n5 {
    margin: -5rem !important; }
  .mt-xxs-n5,
  .my-xxs-n5 {
    margin-top: -5rem !important; }
  .mr-xxs-n5,
  .mx-xxs-n5 {
    margin-right: -5rem !important; }
  .mb-xxs-n5,
  .my-xxs-n5 {
    margin-bottom: -5rem !important; }
  .ml-xxs-n5,
  .mx-xxs-n5 {
    margin-left: -5rem !important; }
  .m-xxs-n6 {
    margin: -6rem !important; }
  .mt-xxs-n6,
  .my-xxs-n6 {
    margin-top: -6rem !important; }
  .mr-xxs-n6,
  .mx-xxs-n6 {
    margin-right: -6rem !important; }
  .mb-xxs-n6,
  .my-xxs-n6 {
    margin-bottom: -6rem !important; }
  .ml-xxs-n6,
  .mx-xxs-n6 {
    margin-left: -6rem !important; }
  .m-xxs-auto {
    margin: auto !important; }
  .mt-xxs-auto,
  .my-xxs-auto {
    margin-top: auto !important; }
  .mr-xxs-auto,
  .mx-xxs-auto {
    margin-right: auto !important; }
  .mb-xxs-auto,
  .my-xxs-auto {
    margin-bottom: auto !important; }
  .ml-xxs-auto,
  .mx-xxs-auto {
    margin-left: auto !important; } }

@media (min-width: 568px) {
  .m-xs-0 {
    margin: 0 !important; }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important; }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important; }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important; }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important; }
  .m-xs-1 {
    margin: 0.25rem !important; }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.25rem !important; }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.25rem !important; }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.25rem !important; }
  .m-xs-2 {
    margin: 0.5rem !important; }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.5rem !important; }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.5rem !important; }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.5rem !important; }
  .m-xs-3 {
    margin: 1.15rem !important; }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 1.15rem !important; }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 1.15rem !important; }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 1.15rem !important; }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 1.15rem !important; }
  .m-xs-4 {
    margin: 2.25rem !important; }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 2.25rem !important; }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 2.25rem !important; }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 2.25rem !important; }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 2.25rem !important; }
  .m-xs-5 {
    margin: 5rem !important; }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 5rem !important; }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 5rem !important; }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 5rem !important; }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 5rem !important; }
  .m-xs-6 {
    margin: 6rem !important; }
  .mt-xs-6,
  .my-xs-6 {
    margin-top: 6rem !important; }
  .mr-xs-6,
  .mx-xs-6 {
    margin-right: 6rem !important; }
  .mb-xs-6,
  .my-xs-6 {
    margin-bottom: 6rem !important; }
  .ml-xs-6,
  .mx-xs-6 {
    margin-left: 6rem !important; }
  .p-xs-0 {
    padding: 0 !important; }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important; }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important; }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important; }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important; }
  .p-xs-1 {
    padding: 0.25rem !important; }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.25rem !important; }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.25rem !important; }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.25rem !important; }
  .p-xs-2 {
    padding: 0.5rem !important; }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.5rem !important; }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.5rem !important; }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.5rem !important; }
  .p-xs-3 {
    padding: 1.15rem !important; }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 1.15rem !important; }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 1.15rem !important; }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 1.15rem !important; }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 1.15rem !important; }
  .p-xs-4 {
    padding: 2.25rem !important; }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 2.25rem !important; }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 2.25rem !important; }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 2.25rem !important; }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 2.25rem !important; }
  .p-xs-5 {
    padding: 5rem !important; }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 5rem !important; }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 5rem !important; }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 5rem !important; }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 5rem !important; }
  .p-xs-6 {
    padding: 6rem !important; }
  .pt-xs-6,
  .py-xs-6 {
    padding-top: 6rem !important; }
  .pr-xs-6,
  .px-xs-6 {
    padding-right: 6rem !important; }
  .pb-xs-6,
  .py-xs-6 {
    padding-bottom: 6rem !important; }
  .pl-xs-6,
  .px-xs-6 {
    padding-left: 6rem !important; }
  .m-xs-n1 {
    margin: -0.25rem !important; }
  .mt-xs-n1,
  .my-xs-n1 {
    margin-top: -0.25rem !important; }
  .mr-xs-n1,
  .mx-xs-n1 {
    margin-right: -0.25rem !important; }
  .mb-xs-n1,
  .my-xs-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xs-n1,
  .mx-xs-n1 {
    margin-left: -0.25rem !important; }
  .m-xs-n2 {
    margin: -0.5rem !important; }
  .mt-xs-n2,
  .my-xs-n2 {
    margin-top: -0.5rem !important; }
  .mr-xs-n2,
  .mx-xs-n2 {
    margin-right: -0.5rem !important; }
  .mb-xs-n2,
  .my-xs-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xs-n2,
  .mx-xs-n2 {
    margin-left: -0.5rem !important; }
  .m-xs-n3 {
    margin: -1.15rem !important; }
  .mt-xs-n3,
  .my-xs-n3 {
    margin-top: -1.15rem !important; }
  .mr-xs-n3,
  .mx-xs-n3 {
    margin-right: -1.15rem !important; }
  .mb-xs-n3,
  .my-xs-n3 {
    margin-bottom: -1.15rem !important; }
  .ml-xs-n3,
  .mx-xs-n3 {
    margin-left: -1.15rem !important; }
  .m-xs-n4 {
    margin: -2.25rem !important; }
  .mt-xs-n4,
  .my-xs-n4 {
    margin-top: -2.25rem !important; }
  .mr-xs-n4,
  .mx-xs-n4 {
    margin-right: -2.25rem !important; }
  .mb-xs-n4,
  .my-xs-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-xs-n4,
  .mx-xs-n4 {
    margin-left: -2.25rem !important; }
  .m-xs-n5 {
    margin: -5rem !important; }
  .mt-xs-n5,
  .my-xs-n5 {
    margin-top: -5rem !important; }
  .mr-xs-n5,
  .mx-xs-n5 {
    margin-right: -5rem !important; }
  .mb-xs-n5,
  .my-xs-n5 {
    margin-bottom: -5rem !important; }
  .ml-xs-n5,
  .mx-xs-n5 {
    margin-left: -5rem !important; }
  .m-xs-n6 {
    margin: -6rem !important; }
  .mt-xs-n6,
  .my-xs-n6 {
    margin-top: -6rem !important; }
  .mr-xs-n6,
  .mx-xs-n6 {
    margin-right: -6rem !important; }
  .mb-xs-n6,
  .my-xs-n6 {
    margin-bottom: -6rem !important; }
  .ml-xs-n6,
  .mx-xs-n6 {
    margin-left: -6rem !important; }
  .m-xs-auto {
    margin: auto !important; }
  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important; }
  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important; }
  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important; }
  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important; } }

@media (min-width: 667px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1.15rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.15rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.15rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.15rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.15rem !important; }
  .m-sm-4 {
    margin: 2.25rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2.25rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2.25rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2.25rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2.25rem !important; }
  .m-sm-5 {
    margin: 5rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 5rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 5rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 5rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 5rem !important; }
  .m-sm-6 {
    margin: 6rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 6rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 6rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 6rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 6rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1.15rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.15rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.15rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.15rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.15rem !important; }
  .p-sm-4 {
    padding: 2.25rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2.25rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2.25rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2.25rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2.25rem !important; }
  .p-sm-5 {
    padding: 5rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 5rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 5rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 5rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 5rem !important; }
  .p-sm-6 {
    padding: 6rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 6rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 6rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 6rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 6rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1.15rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.15rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.15rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.15rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.15rem !important; }
  .m-sm-n4 {
    margin: -2.25rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -2.25rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -2.25rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -2.25rem !important; }
  .m-sm-n5 {
    margin: -5rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -5rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -5rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -5rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -5rem !important; }
  .m-sm-n6 {
    margin: -6rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -6rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -6rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -6rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -6rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1.15rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.15rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.15rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.15rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.15rem !important; }
  .m-md-4 {
    margin: 2.25rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2.25rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2.25rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2.25rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2.25rem !important; }
  .m-md-5 {
    margin: 5rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 5rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 5rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 5rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 5rem !important; }
  .m-md-6 {
    margin: 6rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 6rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 6rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 6rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 6rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1.15rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.15rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.15rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.15rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.15rem !important; }
  .p-md-4 {
    padding: 2.25rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2.25rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2.25rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2.25rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2.25rem !important; }
  .p-md-5 {
    padding: 5rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 5rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 5rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 5rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 5rem !important; }
  .p-md-6 {
    padding: 6rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 6rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 6rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 6rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 6rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1.15rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.15rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.15rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.15rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.15rem !important; }
  .m-md-n4 {
    margin: -2.25rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -2.25rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -2.25rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -2.25rem !important; }
  .m-md-n5 {
    margin: -5rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -5rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -5rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -5rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -5rem !important; }
  .m-md-n6 {
    margin: -6rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -6rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -6rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -6rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -6rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1.15rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.15rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.15rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.15rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.15rem !important; }
  .m-lg-4 {
    margin: 2.25rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2.25rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2.25rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2.25rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2.25rem !important; }
  .m-lg-5 {
    margin: 5rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 5rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 5rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 5rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 5rem !important; }
  .m-lg-6 {
    margin: 6rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 6rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 6rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 6rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 6rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1.15rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.15rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.15rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.15rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.15rem !important; }
  .p-lg-4 {
    padding: 2.25rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2.25rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2.25rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2.25rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2.25rem !important; }
  .p-lg-5 {
    padding: 5rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 5rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 5rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 5rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 5rem !important; }
  .p-lg-6 {
    padding: 6rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 6rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 6rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 6rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 6rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1.15rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.15rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.15rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.15rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.15rem !important; }
  .m-lg-n4 {
    margin: -2.25rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -2.25rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -2.25rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -2.25rem !important; }
  .m-lg-n5 {
    margin: -5rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -5rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -5rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -5rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -5rem !important; }
  .m-lg-n6 {
    margin: -6rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -6rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -6rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -6rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -6rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1.15rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.15rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.15rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.15rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.15rem !important; }
  .m-xl-4 {
    margin: 2.25rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2.25rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2.25rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2.25rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2.25rem !important; }
  .m-xl-5 {
    margin: 5rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 5rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 5rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 5rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 5rem !important; }
  .m-xl-6 {
    margin: 6rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 6rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 6rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 6rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 6rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1.15rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.15rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.15rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.15rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.15rem !important; }
  .p-xl-4 {
    padding: 2.25rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2.25rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2.25rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2.25rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2.25rem !important; }
  .p-xl-5 {
    padding: 5rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 5rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 5rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 5rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 5rem !important; }
  .p-xl-6 {
    padding: 6rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 6rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 6rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 6rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 6rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1.15rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.15rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.15rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.15rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.15rem !important; }
  .m-xl-n4 {
    margin: -2.25rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -2.25rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -2.25rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -2.25rem !important; }
  .m-xl-n5 {
    margin: -5rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -5rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -5rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -5rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -5rem !important; }
  .m-xl-n6 {
    margin: -6rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -6rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -6rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -6rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -6rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1.15rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1.15rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1.15rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1.15rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1.15rem !important; }
  .m-xxl-4 {
    margin: 2.25rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 2.25rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 2.25rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 2.25rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 2.25rem !important; }
  .m-xxl-5 {
    margin: 5rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 5rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 5rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 5rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 5rem !important; }
  .m-xxl-6 {
    margin: 6rem !important; }
  .mt-xxl-6,
  .my-xxl-6 {
    margin-top: 6rem !important; }
  .mr-xxl-6,
  .mx-xxl-6 {
    margin-right: 6rem !important; }
  .mb-xxl-6,
  .my-xxl-6 {
    margin-bottom: 6rem !important; }
  .ml-xxl-6,
  .mx-xxl-6 {
    margin-left: 6rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1.15rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1.15rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1.15rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1.15rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1.15rem !important; }
  .p-xxl-4 {
    padding: 2.25rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 2.25rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 2.25rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 2.25rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 2.25rem !important; }
  .p-xxl-5 {
    padding: 5rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 5rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 5rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 5rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 5rem !important; }
  .p-xxl-6 {
    padding: 6rem !important; }
  .pt-xxl-6,
  .py-xxl-6 {
    padding-top: 6rem !important; }
  .pr-xxl-6,
  .px-xxl-6 {
    padding-right: 6rem !important; }
  .pb-xxl-6,
  .py-xxl-6 {
    padding-bottom: 6rem !important; }
  .pl-xxl-6,
  .px-xxl-6 {
    padding-left: 6rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1.15rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1.15rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1.15rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1.15rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1.15rem !important; }
  .m-xxl-n4 {
    margin: -2.25rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -2.25rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -2.25rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -2.25rem !important; }
  .m-xxl-n5 {
    margin: -5rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -5rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -5rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -5rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -5rem !important; }
  .m-xxl-n6 {
    margin: -6rem !important; }
  .mt-xxl-n6,
  .my-xxl-n6 {
    margin-top: -6rem !important; }
  .mr-xxl-n6,
  .mx-xxl-n6 {
    margin-right: -6rem !important; }
  .mb-xxl-n6,
  .my-xxl-n6 {
    margin-bottom: -6rem !important; }
  .ml-xxl-n6,
  .mx-xxl-n6 {
    margin-left: -6rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

@media (min-width: 1600px) {
  .m-xxxl-0 {
    margin: 0 !important; }
  .mt-xxxl-0,
  .my-xxxl-0 {
    margin-top: 0 !important; }
  .mr-xxxl-0,
  .mx-xxxl-0 {
    margin-right: 0 !important; }
  .mb-xxxl-0,
  .my-xxxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxxl-0,
  .mx-xxxl-0 {
    margin-left: 0 !important; }
  .m-xxxl-1 {
    margin: 0.25rem !important; }
  .mt-xxxl-1,
  .my-xxxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxxl-1,
  .mx-xxxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxxl-1,
  .my-xxxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxxl-1,
  .mx-xxxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxxl-2 {
    margin: 0.5rem !important; }
  .mt-xxxl-2,
  .my-xxxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxxl-2,
  .mx-xxxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxxl-2,
  .my-xxxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxxl-2,
  .mx-xxxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxxl-3 {
    margin: 1.15rem !important; }
  .mt-xxxl-3,
  .my-xxxl-3 {
    margin-top: 1.15rem !important; }
  .mr-xxxl-3,
  .mx-xxxl-3 {
    margin-right: 1.15rem !important; }
  .mb-xxxl-3,
  .my-xxxl-3 {
    margin-bottom: 1.15rem !important; }
  .ml-xxxl-3,
  .mx-xxxl-3 {
    margin-left: 1.15rem !important; }
  .m-xxxl-4 {
    margin: 2.25rem !important; }
  .mt-xxxl-4,
  .my-xxxl-4 {
    margin-top: 2.25rem !important; }
  .mr-xxxl-4,
  .mx-xxxl-4 {
    margin-right: 2.25rem !important; }
  .mb-xxxl-4,
  .my-xxxl-4 {
    margin-bottom: 2.25rem !important; }
  .ml-xxxl-4,
  .mx-xxxl-4 {
    margin-left: 2.25rem !important; }
  .m-xxxl-5 {
    margin: 5rem !important; }
  .mt-xxxl-5,
  .my-xxxl-5 {
    margin-top: 5rem !important; }
  .mr-xxxl-5,
  .mx-xxxl-5 {
    margin-right: 5rem !important; }
  .mb-xxxl-5,
  .my-xxxl-5 {
    margin-bottom: 5rem !important; }
  .ml-xxxl-5,
  .mx-xxxl-5 {
    margin-left: 5rem !important; }
  .m-xxxl-6 {
    margin: 6rem !important; }
  .mt-xxxl-6,
  .my-xxxl-6 {
    margin-top: 6rem !important; }
  .mr-xxxl-6,
  .mx-xxxl-6 {
    margin-right: 6rem !important; }
  .mb-xxxl-6,
  .my-xxxl-6 {
    margin-bottom: 6rem !important; }
  .ml-xxxl-6,
  .mx-xxxl-6 {
    margin-left: 6rem !important; }
  .p-xxxl-0 {
    padding: 0 !important; }
  .pt-xxxl-0,
  .py-xxxl-0 {
    padding-top: 0 !important; }
  .pr-xxxl-0,
  .px-xxxl-0 {
    padding-right: 0 !important; }
  .pb-xxxl-0,
  .py-xxxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxxl-0,
  .px-xxxl-0 {
    padding-left: 0 !important; }
  .p-xxxl-1 {
    padding: 0.25rem !important; }
  .pt-xxxl-1,
  .py-xxxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxxl-1,
  .px-xxxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxxl-1,
  .py-xxxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxxl-1,
  .px-xxxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxxl-2 {
    padding: 0.5rem !important; }
  .pt-xxxl-2,
  .py-xxxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxxl-2,
  .px-xxxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxxl-2,
  .py-xxxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxxl-2,
  .px-xxxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxxl-3 {
    padding: 1.15rem !important; }
  .pt-xxxl-3,
  .py-xxxl-3 {
    padding-top: 1.15rem !important; }
  .pr-xxxl-3,
  .px-xxxl-3 {
    padding-right: 1.15rem !important; }
  .pb-xxxl-3,
  .py-xxxl-3 {
    padding-bottom: 1.15rem !important; }
  .pl-xxxl-3,
  .px-xxxl-3 {
    padding-left: 1.15rem !important; }
  .p-xxxl-4 {
    padding: 2.25rem !important; }
  .pt-xxxl-4,
  .py-xxxl-4 {
    padding-top: 2.25rem !important; }
  .pr-xxxl-4,
  .px-xxxl-4 {
    padding-right: 2.25rem !important; }
  .pb-xxxl-4,
  .py-xxxl-4 {
    padding-bottom: 2.25rem !important; }
  .pl-xxxl-4,
  .px-xxxl-4 {
    padding-left: 2.25rem !important; }
  .p-xxxl-5 {
    padding: 5rem !important; }
  .pt-xxxl-5,
  .py-xxxl-5 {
    padding-top: 5rem !important; }
  .pr-xxxl-5,
  .px-xxxl-5 {
    padding-right: 5rem !important; }
  .pb-xxxl-5,
  .py-xxxl-5 {
    padding-bottom: 5rem !important; }
  .pl-xxxl-5,
  .px-xxxl-5 {
    padding-left: 5rem !important; }
  .p-xxxl-6 {
    padding: 6rem !important; }
  .pt-xxxl-6,
  .py-xxxl-6 {
    padding-top: 6rem !important; }
  .pr-xxxl-6,
  .px-xxxl-6 {
    padding-right: 6rem !important; }
  .pb-xxxl-6,
  .py-xxxl-6 {
    padding-bottom: 6rem !important; }
  .pl-xxxl-6,
  .px-xxxl-6 {
    padding-left: 6rem !important; }
  .m-xxxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxxl-n1,
  .my-xxxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxxl-n1,
  .mx-xxxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxxl-n1,
  .my-xxxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxxl-n1,
  .mx-xxxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxxl-n2,
  .my-xxxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxxl-n2,
  .mx-xxxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxxl-n2,
  .my-xxxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxxl-n2,
  .mx-xxxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxxl-n3 {
    margin: -1.15rem !important; }
  .mt-xxxl-n3,
  .my-xxxl-n3 {
    margin-top: -1.15rem !important; }
  .mr-xxxl-n3,
  .mx-xxxl-n3 {
    margin-right: -1.15rem !important; }
  .mb-xxxl-n3,
  .my-xxxl-n3 {
    margin-bottom: -1.15rem !important; }
  .ml-xxxl-n3,
  .mx-xxxl-n3 {
    margin-left: -1.15rem !important; }
  .m-xxxl-n4 {
    margin: -2.25rem !important; }
  .mt-xxxl-n4,
  .my-xxxl-n4 {
    margin-top: -2.25rem !important; }
  .mr-xxxl-n4,
  .mx-xxxl-n4 {
    margin-right: -2.25rem !important; }
  .mb-xxxl-n4,
  .my-xxxl-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-xxxl-n4,
  .mx-xxxl-n4 {
    margin-left: -2.25rem !important; }
  .m-xxxl-n5 {
    margin: -5rem !important; }
  .mt-xxxl-n5,
  .my-xxxl-n5 {
    margin-top: -5rem !important; }
  .mr-xxxl-n5,
  .mx-xxxl-n5 {
    margin-right: -5rem !important; }
  .mb-xxxl-n5,
  .my-xxxl-n5 {
    margin-bottom: -5rem !important; }
  .ml-xxxl-n5,
  .mx-xxxl-n5 {
    margin-left: -5rem !important; }
  .m-xxxl-n6 {
    margin: -6rem !important; }
  .mt-xxxl-n6,
  .my-xxxl-n6 {
    margin-top: -6rem !important; }
  .mr-xxxl-n6,
  .mx-xxxl-n6 {
    margin-right: -6rem !important; }
  .mb-xxxl-n6,
  .my-xxxl-n6 {
    margin-bottom: -6rem !important; }
  .ml-xxxl-n6,
  .mx-xxxl-n6 {
    margin-left: -6rem !important; }
  .m-xxxl-auto {
    margin: auto !important; }
  .mt-xxxl-auto,
  .my-xxxl-auto {
    margin-top: auto !important; }
  .mr-xxxl-auto,
  .mx-xxxl-auto {
    margin-right: auto !important; }
  .mb-xxxl-auto,
  .my-xxxl-auto {
    margin-bottom: auto !important; }
  .ml-xxxl-auto,
  .mx-xxxl-auto {
    margin-left: auto !important; } }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(8rem) scale(0.8); }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1); } }

@keyframes fadeUpAlt {
  0% {
    opacity: 0;
    transform: translateY(1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeFromRight {
  0% {
    opacity: 0;
    transform: translateX(5rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes fadeFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-5rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/************************************************************************
* margin top
************************************************************************/
/**
* .mdt
*/
.mdt--n {
  margin-top: 0; }

.mdt--xs {
  margin-top: 0.5rem; }

.mdt--s {
  margin-top: 1rem; }

.mdt--m {
  margin-top: 2rem; }

.mdt--l {
  margin-top: 5rem; }

.mdt--xl {
  margin-top: 8rem; }

/************************************************************************
* margin bottom
************************************************************************/
/**
* .mdb
*/
.mdb--n {
  margin-bottom: 0; }

.mdb--xs {
  margin-bottom: 0.5rem; }

.mdb--s {
  margin-bottom: 1rem; }

.mdb--m {
  margin-bottom: 2rem; }

.mdb--l {
  margin-bottom: 5rem; }

.mdb--xl {
  margin-bottom: 8rem; }

/************************************************************************
* margin left
************************************************************************/
/**
* .mdl
*/
.mdl--n {
  margin-left: 0; }

.mdl--xs {
  margin-left: 0.5rem; }

.mdl--s {
  margin-left: 1rem; }

.mdl--m {
  margin-left: 2rem; }

.mdl--l {
  margin-left: 5rem; }

.mdl--xl {
  margin-left: 8rem; }

/************************************************************************
* margin right
************************************************************************/
/**
* .mdr
*/
.mdr--n {
  margin-right: 0; }

.mdr--xs {
  margin-right: 0.5rem; }

.mdr--s {
  margin-right: 1rem; }

.mdr--m {
  margin-right: 2rem; }

.mdr--l {
  margin-right: 5rem; }

.mdr--xl {
  margin-right: 8rem; }

/************************************************************************
* margin vertical
************************************************************************/
/**
* .mdv
*/
.mdv--n {
  margin-top: 0;
  margin-bottom: 0; }

.mdv--xs {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.mdv--s {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.mdv--m {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.mdv--l {
  margin-top: 5rem;
  margin-bottom: 5rem; }

.mdv--xl {
  margin-top: 5rem;
  margin-bottom: 5rem; }

/************************************************************************
* margin horizontal
************************************************************************/
/**
* .mdh
*/
.mdh--n {
  margin-left: 0;
  margin-right: 0; }

.mdh--xs {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.mdh--s {
  margin-left: 1rem;
  margin-right: 1rem; }

.mdh--m {
  margin-left: 2rem;
  margin-right: 2rem; }

.mdh--l {
  margin-left: 5rem;
  margin-right: 5rem; }

.mdh--xl {
  margin-left: 8rem;
  margin-right: 8rem; }

/************************************************************************
* statics
/************************************************************************/
/**
* .pxs
*/
.pxs {
  margin: 0.5rem; }

/**
* .ps
*/
.ps {
  margin: 1rem; }

/**
* .pm
*/
.pm {
  margin: 2rem; }

/**
* .pl
*/
.pl {
  margin: 5rem; }

/**
* .pxl
*/
.pxl {
  margin: 8rem; }

/**
* Transition
*/
/**
* truncate
*/
/**
* posFix
*/
/**
* posAbs
*/
/**
* posAbs
*/
/**
* .form-control
*/
/************************************************************************
* padding top
************************************************************************/
/**
* .pdt
*/
.pdt, .pdt--m {
  padding-top: 2rem; }

/**
* .pdt--xs
*/
.pdt--xs {
  padding-top: 0.5rem; }

/**
* .pdt--s
*/
.pdt--s {
  padding-top: 1rem; }

/**
* .pdt--l
*/
.pdt--l {
  padding-top: 5rem; }

/**
* .pdt--xl
*/
.pdt--xl {
  padding-top: 8rem; }

/************************************************************************
* padding bottom
************************************************************************/
/**
* .pdb
*/
.pdb, .pdb--m {
  padding-bottom: 2rem; }

/**
* .pdb--n
*/
.pdb--n {
  padding-bottom: 0; }

/**
* .pdb--xs
*/
.pdb--xs {
  padding-bottom: 0.5rem; }

/**
* .pdb--s
*/
.pdb--s {
  padding-bottom: 1rem; }

/**
* .pdb--l
*/
.pdb--l {
  padding-bottom: 5rem; }

/**
* .pdb--xl
*/
.pdb--xl {
  padding-bottom: 8rem; }

/************************************************************************
* padding left
************************************************************************/
/**
* .pdl
*/
.pdl, .pdl--m {
  padding-left: 2rem; }

/**
* .pdl--xs
*/
.pdl--xs {
  padding-left: 0.5rem; }

/**
* .pdl--s
*/
.pdl--s {
  padding-left: 1rem; }

/**
* .pdl--l
*/
.pdl--l {
  padding-left: 5rem; }

/**
* .pdl--xl
*/
.pdl--xl {
  padding-left: 8rem; }

/************************************************************************
* padding right
************************************************************************/
/**
* .pdr
*/
.pdr, .pdr--m {
  padding-right: 2rem; }

/**
* .pdr--xs
*/
.pdr--xs {
  padding-right: 0.5rem; }

/**
* .pdr--s
*/
.pdr--s {
  padding-right: 1rem; }

/**
* .pdr--l
*/
.pdr--l {
  padding-right: 5rem; }

/**
* .pdr--xl
*/
.pdr--xl {
  padding-right: 8rem; }

/************************************************************************
* statics
/************************************************************************/
/**
* .pl
*/
.pl {
  padding-left: 2rem; }

/**
* .ps
*/
.ps {
  padding: 1rem; }

/**
* .pm
*/
.pm {
  padding: 1.5rem; }
  @media (min-width: 768px) {
    .pm {
      padding: 2rem; } }

/**
* .pl
*/
.pl {
  padding: 5rem; }

/**
* .pxl
*/
.pxl {
  padding: 8rem; }

/**
* .pn
*/
.pn {
  padding: 0; }

/**
* body
*/
body {
  letter-spacing: 0.045rem;
  font-size: 1rem;
  font-weight: 300;
  overflow-x: hidden;
  color: #3e3e3e; }

/**
* *
*/
* {
  letter-spacing: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

::selection {
  background: #1f293a !important;
  color: #ffffff !important; }

::-moz-selection {
  background: #1f293a !important;
  color: #ffffff !important; }

/**
* .ta
*/
.ta--c {
  text-align: center; }

.ta--l {
  text-align: left; }

.ta--r {
  text-align: right; }

.bg--mono-full {
  background-color: #000000;
  color: #3e3e3e; }
  .bg--mono-full a {
    color: inherit; }

.fill--mono-full {
  fill: #000000; }

.bg--mono-black {
  background-color: #3e3e3e;
  color: #3e3e3e; }
  .bg--mono-black a {
    color: inherit; }

.fill--mono-black {
  fill: #3e3e3e; }

.bg--mono-dark {
  background-color: #464646;
  color: #3e3e3e; }
  .bg--mono-dark a {
    color: inherit; }

.fill--mono-dark {
  fill: #464646; }

.bg--mono-mid {
  background-color: #c9cbcd;
  color: #3e3e3e; }
  .bg--mono-mid a {
    color: inherit; }

.fill--mono-mid {
  fill: #c9cbcd; }

.bg--mono-light {
  background-color: #eaeaea;
  color: #3e3e3e; }
  .bg--mono-light a {
    color: inherit; }

.fill--mono-light {
  fill: #eaeaea; }

.bg--mono-white {
  background-color: #ffffff;
  color: #3e3e3e; }
  .bg--mono-white a {
    color: inherit; }

.fill--mono-white {
  fill: #ffffff; }

.bg--brand-navy {
  background-color: #1f293a;
  color: #ffffff; }
  .bg--brand-navy a {
    color: inherit; }

.fill--brand-navy {
  fill: #1f293a; }

.bg--brand-blue {
  background-color: #3476bc;
  color: #ffffff; }
  .bg--brand-blue a {
    color: inherit; }

.fill--brand-blue {
  fill: #3476bc; }

.bg--brand-gold {
  background-color: #c79354;
  color: #ffffff; }
  .bg--brand-gold a {
    color: inherit; }

.fill--brand-gold {
  fill: #c79354; }

.bg--brand-gold .button,
.bg--brand-gold button,
.bg--brand-gold input[type="submit"] {
  background-color: #1f293a !important; }
  .bg--brand-gold .button:hover,
  .bg--brand-gold button:hover,
  .bg--brand-gold input[type="submit"]:hover {
    background-color: #ffffff !important;
    color: #464646 !important; }

.border-radius {
  border-radius: 5px;
  overflow: hidden; }
  .border-radius + section.border-radius.bg--mono-light {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    margin-top: -20px;
    padding-top: 20px; }

section.border-radius {
  margin-left: 1rem;
  margin-right: 1rem; }
  @media (min-width: 992px) {
    section.border-radius {
      margin-left: 2rem;
      margin-right: 2rem; } }

/**
* .button
*/
.button:not(.blank):not(.muted),
input[type="submit"]:not(.blank):not(.muted),
button:not(.blank):not(.muted) {
  outline: none;
  border: none;
  border-radius: 5px;
  height: 45px;
  line-height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #c79354;
  color: #ffffff;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 700;
  font-size: 0.875rem; }
  .button:not(.blank):not(.muted):hover,
  input[type="submit"]:not(.blank):not(.muted):hover,
  button:not(.blank):not(.muted):hover {
    background-color: #1f293a; }

.button.muted,
input[type="submit"].muted,
button.muted {
  color: #c79354; }
  .button.muted:after,
  input[type="submit"].muted:after,
  button.muted:after {
    content: '\2192';
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px; }
  @media (min-width: 667px) {
    .button.muted,
    input[type="submit"].muted,
    button.muted {
      font-size: 1.375rem; } }

.button.blank,
input[type="submit"].blank,
button.blank {
  padding: 0; }

/**
* button
*/
button {
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 0px; }

/**
* .container-fluid
*/

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container {
  max-width: 1628px;
  padding-left: 2rem;
  padding-right: 2rem; }
  .container-fluid--xs,
  .container--xs {
    max-width: 700px; }
  .container-fluid--sm,
  .container--sm {
    max-width: 1000px; }
  .container-fluid--md,
  .container--md {
    max-width: 1400px; }
  .container-fluid--lg,
  .container--lg {
    max-width: 1900px; }

.hidden,
.none {
  display: none; }

.ff--base {
  font-family: navigo, sans-serif; }

.text--mono-full {
  color: #000000 !important; }
  .text--mono-full > * {
    color: inherit; }

.text--mono-black {
  color: #3e3e3e !important; }
  .text--mono-black > * {
    color: inherit; }

.text--mono-dark {
  color: #464646 !important; }
  .text--mono-dark > * {
    color: inherit; }

.text--mono-mid {
  color: #c9cbcd !important; }
  .text--mono-mid > * {
    color: inherit; }

.text--mono-light {
  color: #eaeaea !important; }
  .text--mono-light > * {
    color: inherit; }

.text--mono-white {
  color: #ffffff !important; }
  .text--mono-white > * {
    color: inherit; }

.text--brand-navy {
  color: #1f293a !important; }
  .text--brand-navy > * {
    color: inherit; }

.text--brand-blue {
  color: #3476bc !important; }
  .text--brand-blue > * {
    color: inherit; }

.text--brand-gold {
  color: #c79354 !important; }
  .text--brand-gold > * {
    color: inherit; }

/**
* h1, h2, h3, h4, h5, h6
*/
h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  margin-bottom: 2rem; }

p {
  margin-bottom: 2rem;
  line-height: 1.8; }

.font--extra-small {
  font-size: 0.667rem;
  line-height: 1.4; }

.font--small {
  font-size: 0.875rem;
  line-height: 1.4; }

.font--reset {
  font-size: 1rem;
  line-height: 1.4; }

.font--medium {
  font-size: 1.375rem;
  line-height: 1.4; }

.font--mid {
  font-size: 1.5rem;
  line-height: 1.4; }

.font--large {
  font-size: 1.85rem;
  line-height: 1.4; }

.font--extra-large {
  font-size: 2.8125rem;
  line-height: 1.4; }

.font--massive {
  font-size: 3.4375rem;
  line-height: 1.4; }

.font--responsive {
  font-size: clamp(1.15rem, calc(1.4vw + 1.45rem), 5rem);
  line-height: 1.4; }

.font--responsive-large {
  font-size: clamp(2.8125rem, calc(10vw + 5rem), 5rem);
  line-height: 1.4; }

h1, h2, h3, h4, h5, h6 {
  line-height: 1.4; }
  h1:not([class*="font--"]), h2:not([class*="font--"]), h3:not([class*="font--"]), h4:not([class*="font--"]), h5:not([class*="font--"]), h6:not([class*="font--"]) {
    font-size: 2.8125rem; }

.text--mono-full {
  color: #000000 !important; }
  .text--mono-full > * {
    color: inherit; }

.text--mono-black {
  color: #3e3e3e !important; }
  .text--mono-black > * {
    color: inherit; }

.text--mono-dark {
  color: #464646 !important; }
  .text--mono-dark > * {
    color: inherit; }

.text--mono-mid {
  color: #c9cbcd !important; }
  .text--mono-mid > * {
    color: inherit; }

.text--mono-light {
  color: #eaeaea !important; }
  .text--mono-light > * {
    color: inherit; }

.text--mono-white {
  color: #ffffff !important; }
  .text--mono-white > * {
    color: inherit; }

.text--brand-navy {
  color: #1f293a !important; }
  .text--brand-navy > * {
    color: inherit; }

.text--brand-blue {
  color: #3476bc !important; }
  .text--brand-blue > * {
    color: inherit; }

.text--brand-gold {
  color: #c79354 !important; }
  .text--brand-gold > * {
    color: inherit; }

/**
* .fw
*/
.fw--light {
  font-weight: 300; }

.fw--normal {
  font-weight: 500; }

.fw--bold {
  font-weight: 700; }

/**
* .tr
*/
.tr--md {
  letter-spacing: 0.2rem; }

.tr--lg {
  letter-spacing: 0.4rem; }

/**
* h1, h2, h3, h4, h5, h6
*/
h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  margin-bottom: 2rem; }

p {
  margin-bottom: 2rem;
  line-height: 1.8; }

.font--extra-small {
  font-size: 0.667rem;
  line-height: 1.4; }

.font--small {
  font-size: 0.875rem;
  line-height: 1.4; }

.font--reset {
  font-size: 1rem;
  line-height: 1.4; }

.font--medium {
  font-size: 1.375rem;
  line-height: 1.4; }

.font--mid {
  font-size: 1.5rem;
  line-height: 1.4; }

.font--large {
  font-size: 1.85rem;
  line-height: 1.4; }

.font--extra-large {
  font-size: 2.8125rem;
  line-height: 1.4; }

.font--massive {
  font-size: 3.4375rem;
  line-height: 1.4; }

.font--responsive {
  font-size: clamp(1.15rem, calc(1.4vw + 1.45rem), 5rem);
  line-height: 1.4; }

.font--responsive-large {
  font-size: clamp(2.8125rem, calc(10vw + 5rem), 5rem);
  line-height: 1.4; }

h1, h2, h3, h4, h5, h6 {
  line-height: 1.4; }
  h1:not([class*="font--"]), h2:not([class*="font--"]), h3:not([class*="font--"]), h4:not([class*="font--"]), h5:not([class*="font--"]), h6:not([class*="font--"]) {
    font-size: 2.8125rem; }

/**
* .tr
*/
.tr--md {
  letter-spacing: 0.2rem; }

.tr--lg {
  letter-spacing: 0.4rem; }

/**
* .fw
*/
.fw--light {
  font-weight: 300; }

.fw--normal {
  font-weight: 500; }

.fw--bold {
  font-weight: 700; }

/**
* input
*/
input:not([type="checkbox"]):not([type="radio"]) {
  border: none;
  outline: none;
  border-radius: 5px;
  height: 45px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: inline-block;
  width: 100%;
  font-size: 1rem; }

input[type="checkbox"], input[type="radio"] {
  display: inline-block;
  vertical-align: middle;
  margin-top: -2.5px; }
  input[type="checkbox"] + label, input[type="radio"] + label {
    margin-left: 0.5rem; }

select {
  border: none;
  outline: none;
  border-radius: 5px;
  height: 45px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: inline-block;
  width: 100%;
  font-size: 1rem; }

/**
* textarea
*/
textarea {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: none;
  outline: none;
  border-radius: 5px;
  height: 45px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: inline-block;
  width: 100%;
  font-size: 1rem;
  min-height: 150px; }

label {
  font-weight: 700;
  display: block;
  font-size: 0.875rem; }
  label input,
  label textarea {
    margin-top: 0.5rem; }

form ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0; }

.gform_description {
  margin-bottom: 2rem;
  display: block; }

.gfield {
  margin-bottom: 1rem; }

.gform_footer input.gform_button {
  width: auto; }

.validation_error {
  background-color: #1f293a;
  color: #ffffff;
  padding: 0.5rem;
  display: inline-block;
  width: auto;
  border-radius: 5px;
  margin-bottom: 1rem; }

.gfield_error .validation_message {
  position: relative;
  z-index: 0;
  font-size: 0.875rem;
  background-color: #1f293a;
  color: #ffffff;
  padding: 0.5rem;
  display: inline-block;
  width: auto;
  border-radius: 5px;
  margin-top: 1rem; }
  .gfield_error .validation_message:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 5px;
    border-bottom: 5px solid #1f293a;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent; }

.gform_ajax_spinner {
  display: inline-block;
  position: relative;
  top: -5px;
  margin-left: 1rem;
  width: 20px;
  height: auto;
  animation: spinner 1s infinite;
  animation-timing-function: linear; }

.ginput_container_consent label {
  display: inline;
  font-weight: 500; }
  .ginput_container_consent label a {
    font-weight: 700; }

.gform_consent {
  margin-bottom: 2rem;
  display: block; }
  .gform_consent label {
    font-size: 1rem;
    width: 100%; }

.grecaptcha-badge {
  visibility: hidden; }

.hidden_label > label {
  display: none; }

/**
* img
*/
img {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: block; }
  img[class*="wp-image"] {
    width: auto;
    height: auto; }

/**
* svg
*/
svg {
  display: inline-block;
  vertical-align: middle;
  width: 2rem;
  height: 2rem; }

/**
* a
*/
a {
  color: #3476bc;
  text-decoration: none;
  font-weight: 500; }
  a:not(.no-hover) {
    border-bottom: 3px solid transparent; }
    a:not(.no-hover):hover {
      border-color: currentColor;
      text-decoration: none; }
  a:hover {
    text-decoration: none; }

/**
* p a
*/
p a {
  font-weight: 700; }

.nbs {
  list-style: none;
  padding-left: 0; }

.box-shadow {
  box-shadow: 0 20px 40px rgba(62, 62, 62, 0.1); }

section.blank h1, section.blank h2, section.blank h3, section.blank h4, section.blank h5, section.blank h6 {
  font-weight: 700; }

section.blank h1 {
  font-size: 3.4375rem; }

section.blank h2, section.blank h3 {
  font-size: 1.85rem; }

section.blank h4, section.blank h5, section.blank h6 {
  font-size: 1.5rem; }

.cc-revoke, .cc-window {
  background-color: #1f293a;
  color: #ffffff;
  font-family: inherit !important; }
  .cc-revoke a, .cc-window a {
    color: #c79354;
    text-decoration: none; }

.cc-btn, .cc-dismiss {
  border-bottom: 2px solid currentColor !important; }
  .cc-btn:hover, .cc-dismiss:hover {
    background-color: #c79354;
    color: #1f293a;
    border-color: transparent !important; }

.areas-covered {
  line-height: 2; }

section.form {
  position: relative;
  z-index: 0; }
  @media (min-width: 768px) {
    section.form:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../resources/images/svgs/curves-white.svg");
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: 100% 0; } }
  @media (min-width: 768px) {
    section.form form {
      max-width: 80%; } }

.hero {
  position: relative;
  z-index: 0; }
  .hero:not(.slim) .hero-content {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 100%; }
  .hero-title {
    max-width: 450px; }
    @media (min-width: 992px) {
      .hero-title {
        max-width: 50%; } }
  .hero-mask {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    mix-blend-mode: hard-light; }
  .hero.slim {
    padding-top: 8rem; }
    @media (min-width: 768px) {
      .hero.slim {
        padding-top: 14rem; } }
    .hero.slim .hero-content {
      padding-bottom: 2rem !important; }

iframe {
  width: 100%; }

.logos {
  position: relative;
  z-index: 0; }
  @media (min-width: 768px) {
    .logos:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../resources/images/svgs/drip.svg");
      background-repeat: no-repeat;
      background-size: auto 150%;
      background-position: center center;
      opacity: 0.35; } }
  .logos hr {
    border-top: 1px solid #eaeaea;
    width: 100%;
    flex-basis: 100%; }
    .logos hr:last-child {
      display: none; }
  .logos-wrapper {
    position: relative;
    z-index: 0; }
    .logos-wrapper:before, .logos-wrapper:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      height: 100%;
      background-color: #eaeaea;
      width: 1px; }
    .logos-wrapper:before {
      left: 33.3%; }
    .logos-wrapper:after {
      left: 66.6%; }

.masthead {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }
  .masthead-logo {
    width: 100%;
    max-width: 65px; }
    @media (min-width: 992px) {
      .masthead-logo {
        max-width: 80px; } }
    .masthead-logo svg {
      height: auto;
      width: 100%; }
  .masthead-nav {
    display: none; }
    @media (min-width: 768px) {
      .masthead-nav {
        display: block; } }

.current-menu-item > a {
  border-bottom: 3px solid #ffffff; }

#mobileNavigation {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none; }
  #mobileNavigation.active {
    display: block; }
    #mobileNavigation.active .container {
      transform-origin: top center; }
  #mobileNavigation a {
    display: inline-block; }

@media (min-width: 667px) {
  .nav-toggle {
    display: none; } }

.mobile-navigation_logo {
  width: 100%;
  max-width: 65px; }
  @media (min-width: 992px) {
    .mobile-navigation_logo {
      max-width: 80px; } }
  .mobile-navigation_logo svg {
    height: auto;
    width: 100%; }

#footerNavigation a {
  display: inline-block; }

.pagination a {
  border-bottom: 0; }

.pagination .row > *, .pagination form ul > *, form .pagination ul > * {
  display: inline-block;
  padding: 1.5px 7.5px;
  border-radius: 5px;
  line-height: 30px;
  font-weight: 700;
  margin-right: 5px; }
  .pagination .row > *:not(.current), .pagination form ul > *:not(.current), form .pagination ul > *:not(.current) {
    background-color: #ffffff;
    color: #3476bc; }
    .pagination .row > *:not(.current):hover, .pagination form ul > *:not(.current):hover, form .pagination ul > *:not(.current):hover {
      background-color: #c79354;
      color: #1f293a; }

.row .pair-content, form ul .pair-content {
  margin-bottom: 2rem; }
  @media (min-width: 992px) {
    .row .pair-content, form ul .pair-content {
      padding-right: 2rem;
      margin-bottom: 0; } }

.row.flex-row-reverse .pair-content, form ul.flex-row-reverse .pair-content {
  margin-top: 2rem; }
  @media (min-width: 992px) {
    .row.flex-row-reverse .pair-content, form ul.flex-row-reverse .pair-content {
      padding-left: 2rem;
      margin-top: 0; } }

.pair-image_holder {
  position: relative;
  z-index: 0; }
  @media (min-width: 992px) {
    .pair-image_holder .pair-image {
      position: sticky;
      top: 8rem; } }

.pair-image {
  height: 100%; }
  .pair-image picture {
    height: 100%;
    display: block; }
  .pair-image img {
    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 992px) {
      .pair-image img {
        height: 100%; } }
    @media (min-width: 1200px) {
      .pair-image img {
        max-width: 600px; } }

.reviews {
  position: relative;
  z-index: 0; }
  @media (min-width: 768px) {
    .reviews:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../resources/images/svgs/curves-alt.svg");
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: 0 0; } }
  .reviews .masonry-left .review {
    border-bottom: 2px solid #ffffff; }
  @media (min-width: 768px) {
    .reviews .masonry-left .review:last-child {
      border-bottom: 0; }
    .reviews .masonry-left .review:not(:last-child) {
      border-bottom: 2px solid #ffffff; } }
  .reviews .masonry-right .review:not(:last-child) {
    border-bottom: 2px solid #ffffff; }

.review-name, .review-location {
  text-transform: capitalize; }

.carousel-navigation {
  transform: translateY(-100%); }

.carousel-navigation_item {
  display: block;
  border: 2px solid #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 100%; }
  .carousel-navigation_item.active {
    background-color: #ffffff; }

.service-icon {
  transform: translateZ(0); }
  .service-icon img {
    width: auto;
    height: 110px;
    display: inline-block; }

article.service {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 460px; }

.telephone-numbers li:not(:last-child):after {
  content: '-';
  margin-right: 5px; }

.textarea {
  position: relative;
  z-index: 0; }
  .textarea h1, .textarea h2, .textarea h3, .textarea h4, .textarea h5, .textarea h6 {
    font-size: 1.85rem !important;
    font-weight: 700;
    color: #1f293a; }
  .textarea-content > *:last-child {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .textarea.drip:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../resources/images/svgs/curves.svg");
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: 100% 0; } }

[id="#truequotecta"]:not(.is-active) {
  display: none; }

._tq-button:not(.muted):not(.blank) {
  height: initial;
  line-height: initial;
  background-color: inherit;
  display: inline-flex;
  color: inherit;
  border: 1px solid transparent; }
  ._tq-button:not(.muted):not(.blank):hover {
    transform: none;
    border: 1px solid transparent;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.33);
    color: white; }
    ._tq-button:not(.muted):not(.blank):hover ._tq-button--phone__contact > * {
      color: currentColor; }
  ._tq-button:not(.muted):not(.blank) svg {
    width: auto; }

.ReactModalPortal ._tq-header--contact a:not(.no-hover):hover {
  border-color: transparent; }

.ReactModalPortal ._tq-modal, .ReactModalPortal ._tq-modal ._tq-container *, .ReactModalPortal ._tq-quote-engine ._tq-container * {
  font-family: navigo, sans-serif;
  text-transform: initial; }

.ReactModalPortal ._tq-modal ._tq-title h2, .ReactModalPortal ._tq-quote-engine ._tq-title h2 {
  font-family: navigo, sans-serif;
  text-transform: initial; }

.ReactModalPortal ._tq-title h1,
.ReactModalPortal ._tq-title h2,
.ReactModalPortal ._tq-title h3,
.ReactModalPortal ._tq-title h4,
.ReactModalPortal ._tq-title h5,
.ReactModalPortal ._tq-title h6 {
  font-weight: 700;
  max-width: 100%; }

@media (prefers-reduced-motion: no-preference) {
  .masthead {
    transition: background ease-in-out 125ms; }
  .button,
  input[type="submit"],
  button {
    transition: background ease-in-out 125ms, transform ease-in-out 125ms, box-shadow ease-in-out 125ms;
    will-change: background, transform, box-shadow; }
    .button:not(.blank):not(.muted):hover,
    input[type="submit"]:not(.blank):not(.muted):hover,
    button:not(.blank):not(.muted):hover {
      transform: translateY(-2.5px);
      box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.75); }
  .service-icon img {
    transition: transform ease-in-out 125ms;
    will-change: transform; }
    .service-icon img:hover {
      transform: scale(1.05); }
  .fadeUp {
    animation: fadeUp 250ms forwards;
    animation-timing-function: ease-in-out; }
  .fadeUpAlt, #mobileNavigation.active .container {
    animation: fadeUpAlt 250ms forwards;
    animation-timing-function: ease-in-out; }
  .fadeOut, #mobileNavigation.inactive {
    animation: fadeOut 250ms forwards;
    animation-timing-function: ease-in-out; }
  .carousel {
    transition: all ease-in-out 125ms; } }
