@each $font-key, $font-value in $font {
    .font--#{$font-key} {
       font-size: $font-value;
       line-height: 1.4;
    }
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1.4;

	&:not([class*="font--"]) {
		font-size: font(extra-large);
	}
}