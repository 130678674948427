@each $color-key, $color-value in $colors {
  @each $tone-key, $tone-value in $color-value {
    @if $tone-key == base {
      .text--#{$color-key} {
        color: $tone-value !important;

        > * {
          color: inherit;
        }
      }
    } @else {
      .text--#{$color-key}-#{$tone-key} {
        color: $tone-value !important;

        > * {
          color: inherit;
        }
      }
    }
  }
}