@media (prefers-reduced-motion: no-preference) {
	.masthead {
		transition: background ease-in-out $timing-fast;
	}

	.button,
	input[type="submit"],
	button { 
		transition: background ease-in-out $timing-fast, transform ease-in-out $timing-fast, box-shadow ease-in-out $timing-fast;
		will-change: background, transform, box-shadow;
		
		&:not(.blank):not(.muted) {
			&:hover {
				transform: translateY(-2.5px);
				box-shadow: 0px 5px 15px -5px rgba( color(mono, full), 0.75 );
			}
		}
 	}

 	.service {
 		&-icon img {
			transition: transform ease-in-out $timing-fast;
			will-change: transform;

			&:hover {
				transform: scale(1.05);
			}
 		}
 	}

 	.fadeUp {
 		animation: fadeUp $timing-default forwards;
 		animation-timing-function: ease-in-out;
 	}

 	.fadeUpAlt {
 		animation: fadeUpAlt $timing-default forwards;
 		animation-timing-function: ease-in-out;
 	}

 	.fadeOut {
 		animation: fadeOut $timing-default forwards;
 		animation-timing-function: ease-in-out;
 	}

 	.carousel {
		transition: all ease-in-out $timing-fast;
 	}
}