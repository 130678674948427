.service {
	&-icon {
		transform: translateZ(0);

		img {
			width: auto;
			height: 110px;
			display: inline-block;
		}
	}
}

article.service {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 460px;
}