/**
* .tr
*/

.tr {

	&--md {

		letter-spacing: 0.2rem;

	}

	&--lg {

		letter-spacing: 0.4rem;

	}

}