/************************************************************************
* margin top
************************************************************************/ 

/**
* .mdt
*/ 

.mdt { 

	&--n {

		margin-top: 0;

	}

	&--xs {

		margin-top: $sp-extra-small;

	}

	&--s {

		margin-top: $sp-small;

	} 

	&--m {

		margin-top: $sp-medium;

	}

	&--l {

		margin-top: $sp-large;

	}

	&--xl {

		margin-top: $sp-extra-large;

	}

}

/************************************************************************
* margin bottom
************************************************************************/ 

/**
* .mdb
*/ 

.mdb {

	&--n {

		margin-bottom: 0;

	}

	&--xs {

		margin-bottom: $sp-extra-small;

	} 

	&--s {

		margin-bottom: $sp-small;

	}

	&--m {

		margin-bottom: $sp-medium;

	}

	&--l {

		margin-bottom: $sp-large;

	}

	&--xl {

		margin-bottom: $sp-extra-large;

	}

}

/************************************************************************
* margin left
************************************************************************/ 

/**
* .mdl
*/ 

.mdl {

	&--n {

		margin-left: 0;

	}

	&--xs {

		margin-left: $sp-extra-small;

	}

	&--s {

		margin-left: $sp-small;

	}

	&--m {

		margin-left: $sp-medium;

	}

	&--l {

		margin-left: $sp-large;

	}

	&--xl {

		margin-left: $sp-extra-large;

	}

}

/************************************************************************
* margin right
************************************************************************/ 

/**
* .mdr
*/ 

.mdr {

	&--n {

		margin-right: 0;

	}

	&--xs {

		margin-right: $sp-extra-small;

	}

	&--s {

		margin-right: $sp-small;

	}

	&--m {

		margin-right: $sp-medium;

	}

	&--l {

		margin-right: $sp-large;

	}

	&--xl {

		margin-right: $sp-extra-large;

	}

}

/************************************************************************
* margin vertical
************************************************************************/ 

/**
* .mdv
*/

.mdv { 

	&--n {

		margin-top: 0;
		margin-bottom: 0;

	}

	&--xs {

		margin-top: $sp-extra-small;
		margin-bottom: $sp-extra-small;

	}

	&--s {

		margin-top: $sp-small;
		margin-bottom: $sp-small;

	}

	&--m {

		margin-top: $sp-medium;
		margin-bottom: $sp-medium;

	}

	&--l {

		margin-top: $sp-large;
		margin-bottom: $sp-large;

	}

	&--xl {

		margin-top: $sp-large;
		margin-bottom: $sp-large;

	}

}

/************************************************************************
* margin horizontal
************************************************************************/ 

/**
* .mdh
*/

.mdh { 

	&--n {

		margin-left: 0;
		margin-right: 0;

	}

	&--xs {

		margin-left: $sp-extra-small;
		margin-right: $sp-extra-small;

	}

	&--s {

		margin-left: $sp-small;
		margin-right: $sp-small;

	}

	&--m {

		margin-left: $sp-medium;
		margin-right: $sp-medium;

	}

	&--l {

		margin-left: $sp-large;
		margin-right: $sp-large;

	}

	&--xl {

		margin-left: $sp-extra-large;
		margin-right: $sp-extra-large;

	}

}

/************************************************************************
* statics
/************************************************************************/

/**
* .pxs
*/ 

.pxs {

	margin: $sp-extra-small;

}

/**
* .ps
*/ 

.ps {

	margin: $sp-small;

}

/**
* .pm
*/ 

.pm {

	margin: $sp-medium;
	
}

/**
* .pl
*/ 

.pl {

	margin: $sp-large;

}

/**
* .pxl
*/ 

.pxl {

	margin: $sp-extra-large;

}