@each $color-key, $color-value in $colors {
    @each $tone-key, $tone-value in $color-value {
        @if $tone-key == base {
            .bg--#{$color-key} {
                background-color: $tone-value;
                color: color(mono, white);

                a {
                    color: inherit;
                }
            }

            .fill--#{$color-key} {
                fill: $tone-value;
            }
        } @else {
            .bg--#{$color-key}-#{$tone-key} {
                background-color: $tone-value;

                @if $color-key == mono {
                    color: color(mono, black);
                } 

                @else {
                    color: color(mono, white);
                }

                a {
                    color: inherit;
                }
            }

            .fill--#{$color-key}-#{$tone-key} {
                fill: $tone-value;
            }
        }
    }
}

.bg--brand-gold {
    .button,
    button,
    input[type="submit"] {
        background-color: color(brand, navy) !important;

        &:hover {
            background-color: color(mono, white) !important;
            color: color(mono, dark) !important;
        }
    }
}