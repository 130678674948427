/**
* h1, h2, h3, h4, h5, h6
*/

h1, h2, h3, h4, h5, h6 {
	font-weight: $fw-light;
	margin-bottom: $sp-medium;
}

p {
	margin-bottom: $sp-medium;
	line-height: 1.8;
}