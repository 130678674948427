/**
* .ta
*/ 

.ta {
	&--c {
		text-align: center;	
	}

	&--l {
		text-align: left;	
	}

	&--r {
		text-align: right;	
	}
}