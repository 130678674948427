/**
* spacers
*/ 

$lh: $spacer * 2;

/**
* used for margins
*/ 

$sp-extra-small: $lh / 4;
$sp-small: $lh / 2;
$sp-medium: $lh;
$sp-large: $lh * 2.5;
$sp-extra-large: $lh * 4;

/**
* heights
*/ 

$sp-default-height: $sp-medium * 1.5;

/**
* other
*/

$border-radius: 5px;