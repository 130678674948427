.cc-revoke, .cc-window {
	background-color: color(brand, navy);
	color: color(mono, white);
	font-family: inherit !important;

	a {
		color: color(brand, gold);
		text-decoration: none;
	}
}

.cc-btn, .cc-dismiss {
	border-bottom: 2px solid currentColor !important;

	&:hover {
		background-color: color(brand, gold);
		color: color(brand, navy);
		border-color: transparent !important;
	}
}