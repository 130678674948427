.reviews {
	@include posRel;

	&:after {
		@include media-breakpoint-up(md) {
			content: '';
			@include posAbs(-1);
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;	
			background-image: url('../resources/images/svgs/curves-alt.svg');
			background-repeat: no-repeat;
			background-size: auto 100%;
			background-position: 0 0;
		}
	}

	.masonry {
		&-left {
			.review {
				border-bottom: 2px solid color(mono, white);
			}

			@include media-breakpoint-up(md) {
				.review:last-child {
					border-bottom: 0;
				}
				
				.review:not(:last-child) {
					border-bottom: 2px solid color(mono, white);
				}
			}
		}

		&-right {
			.review:not(:last-child) {
				border-bottom: 2px solid color(mono, white);
			}
		}
	}
}

.review {
	&-name,
	&-location {
		text-transform: capitalize;
	}
}

.carousel-navigation {
	transform: translateY(-100%);
}

.carousel-navigation_item {
	display: block;
	border: 2px solid color(mono, white);
	width: 20px;
	height: 20px;
	border-radius: 100%;

	&.active {
		background-color: color(mono, white);
	}
}