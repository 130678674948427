/**
* a
*/

a { 
	color: color(brand, blue);
	text-decoration: none;
	font-weight: $fw-normal;

	&:not(.no-hover) {
		border-bottom: 3px solid transparent;

		&:hover {
			border-color: currentColor;
			text-decoration: none;

			svg {
				fill: color(mono, base);
			}
		}
	}

	&:hover {
		text-decoration: none;
	}
}

/**
* p a
*/ 

p a {
	font-weight: $fw-bold;
}