.pagination {

	a {
		border-bottom: 0;
	}

	.row > * {
		display: inline-block;
		padding: 1.5px 7.5px;
		border-radius: 5px;
		line-height: 30px;
		font-weight: $fw-bold;
		margin-right: 5px;

		&:not(.current) {
			background-color: color(mono, white);
			color: color(brand, blue);

			&:hover {
				background-color: color(brand, gold);
				color: color(brand, navy);
			}
		}
	}

}