/**
* .fw
*/

.fw {

	&--light {

		font-weight: $fw-light;

	}

	&--normal {

		font-weight: $fw-normal;

	}

	&--bold {

		font-weight: $fw-bold;

	}

}