/**
* Transition
*/

@mixin transition($effect: all, $time: $timing-fast, $ease: ease-in-out) {

	transition: $effect $time $ease;

}

/**
* truncate
*/

@mixin truncate() {

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

}

/**
* posFix
*/

@mixin posFix($index: 0) {

	position: fixed;
	z-index: $index;

}

/**
* posAbs
*/

@mixin posAbs($index: 0) {

	position: absolute;
	z-index: $index;

}

/**
* posAbs
*/

@mixin posRel($index: 0) {

	position: relative;
	z-index: $index;

}

/**
* .form-control
*/

@mixin form-control {
	border: none;
	outline: none;
	border-radius: $border-radius;
	height: 45px;
	padding-left: $sp-small;
	padding-right: $sp-small;
	display: inline-block;
	width: 100%;
	font-size: font(reset);
}

@mixin btn {
	outline: none;
	border: none;
	border-radius: 5px;
	height: 45px;
	line-height: 45px;
	padding-left: 20px;
	padding-right: 20px;
	background-color: color(brand, gold);
	color: color(mono, white);
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 0.1rem;	
	font-weight: $fw-bold;
	font-size: font(small);

	&:hover {
		background-color: color(brand, navy);
	}
}
