// mono 

$colors: (
  mono: (
  	full: #000000,
    black: #3e3e3e,
    dark: #464646,
    mid: #c9cbcd,
    light: #eaeaea,
    white: #ffffff,
  ),
  brand: (
    navy: #1f293a,
    blue: #3476bc,
    gold: #c79354,
  )
);

/**
* colors
*/ 
$gradient-master: linear-gradient(0deg, rgba(#009A96, 1) 0%, rgba(#00a090,1) 100%);
$gradient-master-dark: linear-gradient(0deg, rgba(darken(#009A96, 5%), 1) 0%, rgba(darken(#00a090, 5%),1) 100%);
