.current-menu-item > a {
	border-bottom: 3px solid color(mono, white);
}

#mobileNavigation {
	@include posFix(10);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;

	&.active {
		display: block;

		.container {
			@extend .fadeUpAlt;
			transform-origin: top center;
		}
	}

	&.inactive {
		@extend .fadeOut;
	}

	a {
		display: inline-block;
	}
}

.nav-toggle {
	@include media-breakpoint-up(sm) {
		display: none;
	}
}

.mobile-navigation_logo {
	width: 100%;
	max-width: 65px;

	@include media-breakpoint-up(lg) {
		max-width: 80px;
	}

	svg {
		height: auto;
		width: 100%;
	}
}

#footerNavigation {
	a {
		display: inline-block;
	}
}