section.blank {

	h1, h2, h3, h4, h5, h6 {
		font-weight: $fw-bold;
	}

	h1 {
		font-size: font(massive);
	}

	h2, h3 {
		font-size: font(large);
	}

	h4, h5, h6 {
		font-size: font(mid);
	}

}