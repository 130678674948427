[id="#truequotecta"] {
    &:not(.is-active) {
        display: none;        
    }
}

._tq-button:not(.muted):not(.blank) {
    height: initial;
    line-height: initial;
    background-color: inherit;
    display: inline-flex;
    color: inherit;
    border: 1px solid transparent;

    &:hover {
        transform: none;
        border: 1px solid transparent;  
        box-shadow: 0 10px 30px rgba(0,0,0,.33);
        color: white;

        ._tq-button--phone__contact > * {
            color: currentColor;
        }
    }

    svg {
        width: auto;
    }
}

.ReactModalPortal {
    ._tq-header--contact a:not(.no-hover):hover {
        border-color: transparent;
    }

    ._tq-modal, ._tq-modal ._tq-container *, ._tq-quote-engine ._tq-container * {
        font-family: navigo, sans-serif;
        text-transform: initial;
    }

    ._tq-modal ._tq-title h2, ._tq-quote-engine ._tq-title h2 {
        font-family: navigo, sans-serif;
        text-transform: initial;
    }

    ._tq-title h1,
    ._tq-title h2,
    ._tq-title h3,
    ._tq-title h4,
    ._tq-title h5,
    ._tq-title h6 {
        font-weight: 700;
        max-width: 100%;
    }
}