section.form {
	@include posRel;

	&:after {
		@include media-breakpoint-up(md) {
			content: '';
			@include posAbs(-1);
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;	
			background-image: url('../resources/images/svgs/curves-white.svg');
			background-repeat: no-repeat;
			background-size: auto 100%;
			background-position: 100% 0;
		}
	}

	@include media-breakpoint-up(md) {
		form {
			max-width: 80%;
		}
	}
}