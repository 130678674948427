.row .pair-content {
	margin-bottom: $sp-medium;

	@include media-breakpoint-up(lg) {
		padding-right: $sp-medium;
		margin-bottom: 0;
	}
}

.row.flex-row-reverse .pair-content {
	margin-top: $sp-medium;
	@include media-breakpoint-up(lg) {
		padding-left: $sp-medium;
		margin-top: 0;
	}
}

.pair-image_holder {
	@include posRel;

	@include media-breakpoint-up(lg) {
		.pair-image {
			position: sticky;
			top: $sp-extra-large;
		}
	}
}

.pair-image {
	height: 100%;

	picture {
		height: 100%;
		display: block;
	}

	img {
		object-fit: cover;
		display: block;
		margin-left: auto;
		margin-right: auto;

		@include media-breakpoint-up(lg) {
			height: 100%;
		}

		@include media-breakpoint-up(xl) {
			max-width: 600px;
		}
	}
}