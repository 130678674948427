/**
* input
*/

input { 
	&:not([type="checkbox"]):not([type="radio"]) {
		@include form-control;
	}

	&[type="checkbox"],
	&[type="radio"] {
		display: inline-block;
		vertical-align: middle;
		margin-top: -2.5px;

		+ label {
			margin-left: $sp-extra-small;
		}
	}
}

select {
	@include form-control;
}

/**
* textarea
*/

textarea { 
	padding-top: $sp-small;
	padding-bottom: $sp-small;
	@include form-control;
	min-height: 150px;
}

label {
	font-weight: $fw-bold;
	display: block;
	font-size: font(small);

	input,
	textarea {
		margin-top: $sp-extra-small;
	}
}

form { 

	ul {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
		@extend .row;
	}

}

.gform_description {
	margin-bottom: $sp-medium;
	display: block;
}

.gfield {
	@extend .col-12;
	margin-bottom: $sp-small;

	&:not(.gform_textarea) {
		@extend .col-md-6;
	}
}

.gform_footer {
	input.gform_button {
		width: auto;
		// background-color: color(brand, navy) !important;
		// color: color(mono, white) !important;
	}
}

.validation_error {
	background-color: color(brand, navy);
	color: color(mono, white);
	padding: $sp-extra-small;
	display: inline-block;
	width: auto;
	border-radius: $border-radius;
	margin-bottom: $sp-small;
}

.gfield_error .validation_message {
	@include posRel;
	font-size: font(small);
	background-color: color(brand, navy);
	color: color(mono, white);
	padding: $sp-extra-small;
	display: inline-block;
	width: auto;
	border-radius: $border-radius;
	margin-top: $sp-small;

	&:before {
		content: '';
		position: absolute;
		bottom: 100%;
		left: 5px;
		border-bottom: 5px solid color(brand, navy);
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
	}
}

.gform_ajax_spinner {
	display: inline-block;
	position: relative;
	top: -5px;
	margin-left: $sp-small;
	width: 20px;
	height: auto;
	animation: spinner 1s infinite;
	animation-timing-function: linear;
}

.ginput_container_consent {
	label {
		display: inline;
		font-weight: $fw-normal;

		a {
			font-weight: $fw-bold;
		}
	}
}

.gform_consent {
	margin-bottom: $sp-medium;
	display: block;

	label {
		font-size: font(reset);
		width: 100%;
	}
}

.grecaptcha-badge {
	visibility: hidden;
}

.hidden_label > label {
	display: none;
}