$font-size-base: 16px;
$font-family-base: navigo, sans-serif;
$line-height-base: 1.5;
$paragraph-margin-bottom: $spacer;

// font weights
$fw-light: 300;
$fw-normal: 500;
$fw-bold: 700;
$font-weight-base: $fw-light;

/**
* .fonts
*/

$font: (
	extra-small: 0.667rem,
	small: 0.875rem,
	reset: 1rem,
	medium: 1.375rem,
	mid: 1.5rem,
	large: 1.85rem,
	extra-large: 2.8125rem,
	massive: 3.4375rem,
	responsive: clamp(1.15rem, calc(1.4vw + 1.45rem), 5rem),
	responsive-large: clamp(2.8125rem, calc(10vw + 5rem), 5rem),
);