/**
* img
*/

img { 
	max-width: 100%;
	width: 100%;
	height: auto;
	display: block;

	&[class*="wp-image"] {
		width: auto;
		height: auto;
	}
}

/**
* svg
*/

svg { 
	display: inline-block;
	vertical-align: middle;
	width: $sp-medium;
	height: $sp-medium;
}