.border-radius { 
	border-radius: $border-radius;
	overflow: hidden;

	& + section.border-radius.bg--mono-light {
		border-top-right-radius: 0 !important;
		border-top-left-radius: 0 !important;
		margin-top: -20px;
		padding-top: 20px;
	}
}

section.border-radius {
	margin-left: $sp-small;
	margin-right: $sp-small;

	@include media-breakpoint-up(lg) {
		margin-left: $sp-medium;
		margin-right: $sp-medium;
	}
}