.logos {
	@include posRel; 

	&:after {
		@include media-breakpoint-up(md) {
			content: '';
			@include posAbs(-1);
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;	
			background-image: url('../resources/images/svgs/drip.svg');
			background-repeat: no-repeat;
			background-size: auto 150%;
			background-position: center center;
			opacity: 0.35;
		}
	}

	img {
		// mix-blend-mode: multiply;
	}

	hr {
		border-top: 1px solid color(mono, light);
		width: 100%;
		flex-basis: 100%;

		&:last-child {
			display: none;
		}
	}

	&-wrapper {
		@include posRel(0); 

		&:before,
		&:after {
			content: '';
			@include posAbs(-1);
			top: 0;
			height: 100%;
			background-color: color(mono, light);
			width: 1px;
		}

		&:before {
			left: 33.3%;
		}

		&:after {
			left: 66.6%;
		}
	}
}