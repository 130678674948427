.hero {
	@include posRel;

	&:not(.slim) .hero-content {
		@include posAbs;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	&-title {
		max-width: 450px;

		@include media-breakpoint-up(lg) {
			max-width: 50%;
		}
	}

	&-mask {
		@include posAbs(0);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.9;
		mix-blend-mode: hard-light;
	}

	&.slim {
		padding-top: $sp-extra-large;

		@include media-breakpoint-up(md) {
			padding-top: $sp-extra-large * 1.75;	
		}

		.hero-content {
			padding-bottom: $sp-medium !important;
		}
	}
}