.masthead {
	@include posFix(1);
	top: 0;
	left: 0;
	width: 100%;

	&-logo {
		width: 100%;
		max-width: 65px;

		@include media-breakpoint-up(lg) {
			max-width: 80px;
		}

		svg {
			height: auto;
			width: 100%;
		}
	}

	&-nav {
		display: none;

		@include media-breakpoint-up(md) {
			display: block;
		}

	}
}