.textarea {
	@include posRel;

	h1, h2, h3, h4, h5, h6 {
		font-size: font(large) !important;
		font-weight: $fw-bold;
		color: color(brand, navy);
	}

	&-content {
		> *:last-child {
			margin-bottom: 0;
		}
	}

	&.drip {

		&:after {
			@include media-breakpoint-up(md) {
				content: '';
				@include posAbs(-1);
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;	
				background-image: url('../resources/images/svgs/curves.svg');
				background-repeat: no-repeat;
				background-size: auto 100%;
				background-position: 100% 0;
			}
		}

	}
}